
/*************************/
/* Angular Material      */
/*************************/
:root{
  --color-invalid: #d9534f;
  --color-primary: #3f51b5;
  --color-subtle-caution: #fcf8e3;
  --color-subtle-primary: #d9edf7;
  --color-subtle-warning: #f2dede;
  --font-family: Roboto, "Helvetica Neue", sans-serif;
  --mat-menu-container-color : white;
  --mdc-filled-text-field-label-text-size: 12px;
  --mat-select-trigger-text-size: 12px;
  --mdc-checkbox-selected-icon-color: var(--color-primary);
  --mdc-checkbox-selected-focus-icon-color: var(--color-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--color-primary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-primary);
  --mat-table-header-container-height: 40px;
  --mdc-icon-button-state-layer-size: 32px;
}

html, body { height: 100%; }
/* Can't enable yet */ /* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* Need to disable material font for now */
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-tab-group.mat-mdc-tab-group.mat-primary {
    font-family: inherit;
}


/* Changes style to show red when in valid on page render, replaces the ng-invalid styles below for ng mat componetns */
.mat-mdc-form-field.ng-invalid .mat-form-field-ripple,
.mat-form-field.ng-invalid .mat-form-field-underline::before,
.mat-form-field.ng-invalid .mat-form-field-underline,
aims-lookup.ng-invalid .mat-form-field-underline,
.mat-mdc-raised-button.mat-warn,
.mat-mdc-mini-fab.mat-warn,
.mat-tooltip-warn
 {
    background-color: var(--color-invalid);
}
/* mat-dialog-content aka lookupPopup should be above aims-popup-editor */
.cdk-overlay-container{
  z-index:20000;
}

/* mat-form-field required fields styling, helpful in aims-lookups*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.required .mat-form-field-underline{
  background-color: var(--color-invalid);
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.required:hover .mat-form-field-underline{
  height: 2px;
}
/* <aims-lookup padding so they are similar to material inputs in the common popup editor. But we don't want this inside <aims-form>
  Padding inside the input ( vs no padding (aims-forms)
 */
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-dialog-content aims-lookup .mat-form-field-infix,
td aims-lookup .mat-form-field-infix{
  margin-left: 10px;
}

/* mat-error with more than one line push into the next line*/
.mat-mdc-dialog-content .mat-mdc-form-field-subscript-wrapper[ng-reflect-ng-switch="error"],
.mat-form-field.mat-mdc-form-field-error .mat-form-field-subscript-wrapper{
  position: static;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field .mat-form-field-underline {
  position: relative;
  bottom: auto;
}
/*mat-errors don't show up when using template driven aims-form. Forcing the mat-hint to show errors*/
.mat-mdc-form-field.mat-mdc-form-field-error mat-hint{
  color: var(--color-invalid);
  font-size:11px;
}
.warningmessage
{
	font-weight: bold;
  color: var(--color-invalid);
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-form-field.ng-invalid mat-label,
.mat-form-field.ng-invalid .mat-placeholder-required,
.mat-form-field.ng-invalid .mat-placeholder,
.mat-mdc-outlined-button.mat-warn .mat-button-wrapper,
aims-lookup.ng-invalid .mat-placeholder-required,
aims-lookup.ng-invalid .lookupMagGlass,
.mat-icon.mat-warn,
.mat-form-field.required input,
.mat-card.mat-warn
{
    color: var(--color-invalid);
}

button.mat-mdc-raised-button
{
    border-top: 1px solid #e8e8e8;
}

.mat-mdc-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
  background-color:var(--color-primary);
}
.mat-mdc-card.mat-warn{
  background-color: var(--color-subtle-warning);
  border-color: var(--color-subtle-warning);
  box-shadow:none
}
.mat-mdc-card:not([class*=mat-elevation-z]) {
    padding:10px 15px;
}
/* Tables within a mat-tab need padding otherwise they get truncated*/
div.table-container{
  padding: 10px 16px 20px 16px;
}

/* Expanded row - eliminate border styling when using the mat-card for styling */
.element-detail .mat-mdc-card{
  background:none;
  box-shadow:none;
}

.no-records {
  font-size: 16px;
}
/* <aims-fc> inside a tables expanded Row forced a larger font */
table .mat-column-expandedDetail aims-fc{
  font-size:11px;
}
/* Material datepicker icon */
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
div .mat-form-field-appearance .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
div .mat-form-field-appearance .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 1.5em;
}
/* Material datepicker icon ripple */
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
div .mat-form-field-appearance .mat-form-field-suffix .mat-datepicker-toggle .mat-focus-indicator
{
  width:2.5em;
  height:2.5em;
}


/*************/
aims-fc mat-form-field {
    width: 100%;
}
/* Multi-line Tooltips - pre-line allows either \n or &#13; as a break*/
.matToolTipMultiLine{
  white-space: pre-line;
}

div.fullWidthMatFormField>textarea {
    border-style: solid;
    border-color: darkgrey;
    border-width: 1px;
    margin: 3px 0px 10px 0px;
}

/*************************/
/* Current global styles */
/*************************/

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-wrapper {
    padding-top: 10px;
}

.centered {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top20 {
    margin-top: 20px;
  }

  .top10 {
    margin-top: 10px;
  }

  .top5 {
    margin-top: 5px;
  }

  .vcenter {
    display: flex;
    align-items: center;
  }

  .vcenter-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .flex-container{
    display:flex;
  }
  .flex-child{
    flex:1;
  }


  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .row.no-gutters > [class^="col-"],
  .row.no-gutters > [class*=" col-"],
  .row.no-gutters > * > [class^="col-"],
  .row.no-gutters > * > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .ng-valid[required="true"]:not(quill-editor):not(mat-form-field):not(.mat-mdc-input-element):not(.mat-mdc-select):not(aims-lookup):not(aims-numeric),
  .ng-valid[required].ng-valid:not([required="false"]):not(quill-editor):not(mat-form-field):not(.mat-mdc-input-element):not(.mat-mdc-select):not(aims-lookup):not(aims-numeric),
  .ng-valid.required:not(quill-editor):not(mat-form-field):not(.mat-mdc-input-element):not(.mat-mdc-select):not(aims-lookup):not(aims-numeric),
  quill-editor.ng-valid > div > .ql-editor:not([contenteditable='false']) {
    border-left: 5px solid #42A948;
    width: calc(100% - 5px);
  }

  .ng-invalid:not(form).ng-invalid:not(quill-editor):not(mat-form-field):not(.mat-mdc-input-element):not(.mat-mdc-select):not(aims-lookup):not(aims-numeric), quill-editor.ng-invalid > div > .ql-editor {
    border-left: 5px solid #a94442;
    width: calc(100% - 5px);
  }


  span.ng-invalid:not(quill-editor){
    padding:3px 0px 5px 0px; /*ng-invalid to entire input*/
  }

  .fullWidth {
    width: 100%!important;
  }


  .oldAimsIframe {
    width: 100%;
    height: calc(100vh - 55px)
  }


  td input[type=checkbox] {
    width: 100%
  }

  .info-popup {
    padding: 30px;
    color: #787878;
    background-color: #fcf7f8;
    border: 1px solid rgba(0,0,0,.05);
  }

  .k-edit-cell > .k-textbox, .k-edit-cell > .k-widget, .k-edit-cell > .text-box, .k-grid-edit-row > td > .k-textbox, .k-grid-edit-row > td > .k-widget, .k-grid-edit-row > td > .text-box {
    margin-left: 0px!important;
  }

  .k-grid tr.rejected {
    background-color: lightsalmon;
  }

  .inactive-user, .q-rejected {
    background-color: lightsalmon !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  mat-tab-header {
    padding-bottom: 5px;
  }

/*************************/
/* fixes issue with telerik reporting select      */
/*************************/
/*  prod version */
  .k-state-selected,
  .k-state-selected > .k-link > .t-font-icon  {
    background-color: #ff6358;
    color: #ffffff;
  }

/* dev version */
  .k-selected,
  .k-selected > .k-link > .t-font-icon  {
    background-color: #ff6358;
    color: #ffffff;
  }

/* Bug in ngx-mat-datetime-picker. Issue #3777 */
td.mat-calendar-body-cell {
  position: relative !important;
}
/* Angular 17 temp, remove once offically released */
ngx-mat-datepicker-content {
  background-color: white;
}
.mat-calendar-body-selected {
    background-color: var(--mat-datepicker-calendar-date-selected-state-background-color);
    color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}
/* button.mat-calendar-body-active {
  background-color: #3f51b5;
} */

div.mat-mdc-paginator-container {
  font-size: small;
}

.mat-icon {
  vertical-align: middle;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 12px !important;
}
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 12px !important;
}

.cdk-overlay-pane {
  width: unset !important;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-tracking: 0;
}

.mat-mdc-header-row {
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-line-height: 16px;
}


/* reduce whitespace in tables
.mdc-fab--mini {
  width: 36px !important;
  height: 36px !important;
}

div.table-container {
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.mat-mdc-row {
  --mat-table-row-item-container-height: 42px;
}
*/

/* .mat-mdc-form-field-infix {
  padding-top: 18px !important;
  padding-bottom: 0px !important;
  min-height: 46px !important;
} */
